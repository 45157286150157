.footer-bg {
    background-image: url('../../assets/images/footer.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 50px;

}

.main-footer-cn {
    padding-top: 200px;
}

.foot-desc{
    font-family: 'regular';
    color: #1D1D1B;
    font-size: 15px;
}

.foot-head{
    font-size: 20px;
    font-family: 'bold';
    color: #1D1D1B;
}