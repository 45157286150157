
.mission {
    padding: 50px 0;
}

.mission-title {
    font-size: 29px;
    color: #1D1D1B;
    margin-bottom: 15px;
    font-family: 'bold';
}


.mission-quote {
    font-size: 17px;
    margin: 10px 0 45px;
    color: #1D1D1B!important;
    font-family: 'medium';
}


.mission-description {
    font-size: 16px;
    color: #1D1D1B;
    font-family: 'regular';
}
.signature {
    font-family: bold;
    font-size: 25px;
    margin-right: 50px;
}

@media (max-width: 992px) { 
    .missionshape {
        display: none;
    }

    .mission {
        padding: 50px 25px;
    }
 }

